<template>
  <SamplesBrowse />
</template>

<script>
import SamplesBrowse from "@/components/newSamples/SamplesBrowse";

export default {
  name: "Browse",
  components: { SamplesBrowse }
};
</script>

<style scoped></style>
